<template>
  <div v-if="modalOpen > 0" class="modal">
    <div>
      <div class="close_btn">
        <span @click="$emit('modalClose')">
          &times;
        </span>
      </div>
      <temrs1 v-if="modalOpen == 1"/>
      <temrs2 v-if="modalOpen == 2"/>
      <!-- <iframe
        v-else
        :src="modalOpen == 1? src1 : src2"
        width="100%"
        height="100%"
        frameborder="0"
      > -->
      <!-- </iframe> -->
    </div>
  </div>
</template>

<script>
import temrs1 from './terms1.vue'
import temrs2 from './terms2.vue'
export default {
  props: {
    modalOpen: Number,
  },
  components:{temrs1,temrs2},
  data(){
    return{
      src1:"https://docs.wssw.kr/service_policy.pdf",
      src2:"https://docs.wssw.kr/privacy_policy.pdf"
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div.close_btn{
    text-align: right;
    font-size: 2rem;
    span{
      cursor: pointer;
      padding: 0 10px;
    }
  }
  &>div {
    box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  background-color: white;
  width: 90%;
  max-width: 900px;
  height: 70vh;
  padding: 20px;
  }
}
</style>
