
import axios from "axios";
import { defineComponent } from "vue";

interface WebAppInterface {
  postMessage(msg: string): void;
}

declare var goToHome: WebAppInterface;

declare var goToLogin: WebAppInterface;

declare var goToFirstPage: WebAppInterface;

export default defineComponent({
  data() {
    return {
      checkAllValue: 0 as number,
      check_01: 0 as number,
      check_02: 0 as number,
      check_03: 0 as number,
      idx: "" as any,
    };
  },
  mounted() {
    // console.log(this.$route.query);
    this.idx = this.$route.query.idx;
  },

  methods: {
    loginPageMove() {
      // goToLogin.postMessage("success");
      goToFirstPage.postMessage("success");
    },
    agreeAllCheck(mode: number) {
      this.checkAllValue = mode;
      this.check_01 = mode;
      this.check_02 = mode;
      this.check_03 = mode;
    },
    agreeCheck_01(mode: number) {
      this.check_01 = mode;
      if (mode == 0) {
        this.checkAllValue = 0;
      }
    },
    agreeCheck_02(mode: number) {
      this.check_02 = mode;
      if (mode == 0) {
        this.checkAllValue = 0;
      }
    },
    agreeCheck_03(mode: number) {
      this.check_03 = mode;
      if (mode == 0) {
        this.checkAllValue = 0;
      }
    },
    postAgree() {
      axios
        // .post(`${this.API}/auth/agree/${this.idx}`, {
        .post(`https://api.wssw.kr/auth/agree/${this.idx}`, {
          agrees: [
            {
              type: 1,
              value: this.check_01,
            },
            {
              type: 2,
              value: this.check_02,
            },
            {
              type: 3,
              value: this.check_03,
            },
          ],
        })
        .then((res) => {
          // console.log(res);
          try {
            goToHome.postMessage('success');
          } catch (error) {
            console.error(error);
          }
        })
        .catch((error) => {
          console.error(error);
          try {
            goToHome.postMessage('error');
          } catch (error) {
            console.error(error);
          }
        });
    },
  },
});
