<template>
  <div class="id_search wrap">
    <!-- <div class="HomeMoveBtn" @click="HomeMoveMethod()">&times;</div> -->
    <!-- <h1>{{platform}}</h1> -->
    <div class="search" v-if="!numberResult">
      <h1>등록된 휴대전화 번호로 찾기</h1>
      <p>계정 정보 확인을 위한 휴대전화 인증이 필요합니다</p>
      <ul class="form flex">
        <phoneCheck
          @phoneCheckResult="getPhoneResult($event)"
          @userHpFind="userHpFind($event)"
          :modeNum="2"
        />
      </ul>
      <transition name="fade">
        <toast :mode="toast" />
      </transition>
      <div
        class="btn"
        @click="getIdFind(userPhoneAuthCheck)"
        :class="{ active: userPhoneAuthCheck }"
      >
        다음
      </div>
      <!-- <button @click="testMode()">예!!!</button> -->
    </div>
    <idResult
      v-else-if="numberResult === 1"
      :userid="userId"
      @pwPageMove="pwPageMoveId($event)"
    />
    <pwResult 
    v-else-if="numberResult === 2" 
    :userid="userId" 
    :platform="platform"/>

    <pwIdCheck
      v-else-if="numberResult === 3"
      :userid="userId"
      @clickIdCheck="pwIdCheckChange($event)"
      
    />
  </div>
</template>
<script>
import { defineComponent } from "vue";
import mixin from "@/mixin/mixin.js";
import idResult from "@/components/search/id_result.vue";
import pwResult from "@/components/search/pw_result.vue";
import toast from "@/components/alerts/toast.vue";
import phoneCheck from "@/components/checkInputs/phoneCheck.vue";
import axios from "axios";
import pwIdCheck from "@/components/search/pwIdCheck.vue";

export default defineComponent({
  mixins: [mixin],
  components: { idResult, pwResult, toast, phoneCheck, pwIdCheck },
  props: {
    mode: String,
  },
 
  data() {
    return {
      platform:"",
      toast: 0, // 1 전송 2 인증번호 틀림
      numberResult: 0,//0, // 인증번호 진행 결과 1:아이디 프로세스 2:패스워드 프로세스
      userId: "", // 인증번호 검증 완료후 리턴받은 아이디
      userPhoneAuthCheck: false,
      userCellPhoneNumber: "",
      userHpFindCk: Boolean,
    };
  },
   created() {
    this.platform = this.$route.query.platform;
  },
  methods: {
    testMode() {
      this.numberResult = 1;
      this.userId = "ws_test1";
    },
    pwPageMoveId(value) {
      this.userId = value.userid;
      this.numberResult = 2;
    },
    pwIdCheckChange(value) {
      if (value.numberResult === 0) {
        this.userPhoneAuthCheck = false;
        this.userId = "";
      }
      this.numberResult = value.numberResult;
      return;
    },
    toastView(toastNumber) {
      this.toast = toastNumber;
      setTimeout(() => {
        this.toast = 0;
      }, 2000);
    },
    getPhoneResult(value) {
      this.userPhoneAuthCheck = value.userPhoneAuthCheck;
      this.userCellPhoneNumber = value.userCellPhoneNumber;
    },
    userHpFind(value) {
      this.userHpFindCk = value.userHpFind;
    },

    getIdFind(auth) { 

      if (!this.userHpFindCk) {
        this.toastView(5);
        return;
      }
      switch (auth) {
        case true:

          const params = {
            gubun: 1,
            contact: this.userCellPhoneNumber.replace(/\-/gi,''),
            auth_check: 1,
            platform: this.platform
          };
          const phoneNumber = this.userCellPhoneNumber.replace(/\-/gi,'')

           axios
            .get(`${this.API}/auth/find/id`, {
              params,
            })
            .then((res) => {
              if (res.status === 200) {
                if(res.data.errorCode === 2){
                  this.toastView(16);
                  return;
                }
                this.userId = res.data.user_id;
                if (this.mode === "id") this.numberResult = 1;
                else if (this.mode === "pw") this.numberResult = 3;
              }
               else {
                this.toastView(16); // 아이디를 찾지 못했습니다.
              }
            });
          break;
        case false:
          this.toastView(10); //핸드폰 인증을 해주세요.
          break;
        default:
          break;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
div.id_search {
  width: 90%;
  div.search {
    width: 100%;
  }
  h1 {
    font-size: 1.875rem;
    font-weight: bold;
    text-align: center;
  }
  p {
    font-size: 1.125rem;
    color: #7c7b7b;
    margin: 20px 0;
    text-align: center;
  }
  ul.form {
    max-width: 750px;
    margin: 50px auto;
    gap: 40px;
  }
  div.btn {
    width: 100%;
    max-width: 460px;
    background-color: #dfe2e7;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    color: #fff;
    font-size: 1.125rem;
    margin: 0px auto;
    &.active {
      background: #0880ae;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
