
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    goBack() {
      // this.$router.go(-1);
      history.go(-1);
    },
  },
});
