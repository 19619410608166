import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);
app.use(store);
app.use(router);
app.mount("#app");

if (process.env.NODE_ENV === "development") {
  app.config.globalProperties.API = "https://tapi.wssw.kr";
} else if (process.env.NODE_ENV === "production") {
  app.config.globalProperties.API = "https://api.wssw.kr";
} else {
  throw `undefined NODE_ENV : ${process.env.NODE_ENV}`;
}
