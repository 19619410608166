<template>
  <div v-if="modalOpen > 0" class="modal">
    <div class="modal_wrap flex">
      <transition name="fade">
        <toast :mode="toast" />
      </transition>
      <div class="close_btn">
        <span @click="$emit('modalClose'), chooseBN()"> &times; </span>
      </div>
      <h2>귀사의 사업장을 선택하세요.</h2>
      <ul>
        <li>
          <div class="flex">
            <input
              type="text"
              v-model="BNSearchValue"
              placeholder="사업자번호를 입력해주세요"
            />
            <div class="btn" @click="BNSearchMethod()">조회</div>
          </div>
        </li>
      </ul>
      <table>
        <thead>
          <tr>
            <td>사업자등록번호</td>
            <td>상호명</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in BNArr" :key="i" @click="chooseBN(item)">
            <!-- key 설정 -->
            <td>{{ resultBN }}</td>
            <td>{{ item.w_sangho }}</td>
          </tr>
          <tr>
            <div>{{ errorMsg }}</div>
          </tr>
        </tbody>
        <tbody v-if="BNArr === false">
          <div>사업장 정보가 없습니다.</div>
        </tbody>
      </table>
      <div>
        <p>사업장이 조회되지 않을경우</p>
        <p>1544-6301로 연락 주시길 바랍니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import toast from "@/components/alerts/toast.vue";

export default {
  components: { toast },

  props: {
    modalOpen: Number,
  },
  data() {
    return {
      toast: 0, //

      BNSearchValue: "",
      BNArr: [],
      resultBN: "",
      errorMsg: "",
    };
  },
  watch: {
    BNSearchValue() {
      this.BNSearchValue = this.BNSearchValue.replace(/ /gi, "");
    },
  },
  methods: {
    toastView(toastNumber) {
      this.toast = toastNumber;
      setTimeout(() => {
        this.toast = 0;
      }, 2000);
    },
    chooseBN(item) {
      if (item) {
        this.$emit("chooseBusinessNum", {
          resultBN: this.resultBN,
          WNosw: item.w_nosw,
          wSangho: item.w_sangho,
          DBName: item.w_db_name,
        });
        return;
      }
      this.BNArr = [];
      this.BNSearchValue = "";
    },
    BNSearchMethod() {
      this.BNArr = [];
      this.errorMsg = "";
      if (this.BNSearchValue === "") {
        // console.log(1)
        this.toastView(19); //사업자 번호를 입력해주세요
        return;
      }
      if (
        /^[0-9]{3}-[0-9]{2}-[0-9]{5}$/.test(this.BNSearchValue) ||
        /^[0-9]{10}$/.test(this.BNSearchValue)
      ) {
      } else {
        this.toastView(20); //형식에 맞게 입력해주세요
        return;
      }

      axios
        .get(`${this.API}/auth/userList/${this.BNSearchValue}`)
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            const err = res.data.errorCode;
            if (err == undefined) {
              // 에러코드가 존재하지 않을때
              if (res.data.length === 0) {
                this.BNArr = false;
                return;
              }
              this.resultBN = this.BNSearchValue;
              this.BNArr = res.data;
            } else {
              //에러코드가 존재할때
              this.errorMsg = res.data.msg;
            }
          } else {
            //백엔드에서 정의된 에러 아닌 에러
            console.log("에러발생");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div.close_btn {
    text-align: right;
    font-size: 2rem;
    span {
      cursor: pointer;
      padding: 0 10px;
    }
  }
  & > div {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
    background-color: white;
    width: 90%;
    max-width: 450px;
    height: 50vh;
    padding: 20px;
  }
  div.modal_wrap {
    * {
      width: 100%;
    }
    flex-direction: column;
    h2 {
      font-size: 1.25rem;
      font-weight: bold;
      text-align: center;
    }
    ul {
      div.flex {
        width: 100%;
        height: 50px;
        input {
          height: 100%;
          flex: 1;
          outline: none;
          border: 0px solid #d0d0d0;
          border-bottom: 1px solid #222;
          box-sizing: border-box;
          padding: 0 10px;
        }
        .btn {
          height: 100%;
          line-height: 50px;
          background: #222;
          color: #fff;
          width: 20%;
          font-weight: 100;
        }
      }
    }
    table {
      flex: 1;
      td {
        padding: 10px 0;
        text-align: center;
        width: 50%;
      }
      thead {
        td {
          background: #d0d0d0;
        }
      }
    }
    p {
      text-align: center;
    }
  }
}
</style>
