<template>
  <div v-if="modalOpen" class="modal">
    <div>
      <iframe
        src="https://docs.wssw.kr/service_policy.pdf"
        width="100%"
        height="100%"
        frameborder="0"
      >
      </iframe>
      <button @click="$emit('modalClose')">Close</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalOpen: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 900px;
  height: 700px;
  padding: 5px;
}
</style>
