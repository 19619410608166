<template>
  <div class="pw_check flex">
    <h1>찾으시는 계정이 맞나요?</h1>
    <p>입력하신 휴대전화 번호로 조회되는 아이디는 아래와 같습니다.</p>
    <h2>{{ userid }}</h2>
    <div class="btns flex">
    <div class="btn no" @click="$emit('clickIdCheck', { numberResult: 0 })">
      아닙니다.
    </div>
    <div class="btn" @click="$emit('clickIdCheck', { numberResult: 2 })">
      네,맞습니다.
    </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userid: String,
  },
};
</script>

<style lang="scss" scoped>
div.pw_check{
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 50px;
  h1{
    font-size: 1.875rem;
    font-weight: bold;
  }
  p{
    font-size: 1.25rem;
    text-align: center;
    line-height: 1.5rem;
    color: #7c7b7b;

  }
  h2{
    font-size: 1.75rem;
      color: #2D81FF;
  }
  div.btns{
    gap: 20px;
    width: 100%;
    div.btn{        
        width: 50%;
        max-width: 250px;
        background-color: #0880AE;
        text-align: center;
        height: 50px;
        line-height: 50px;
        cursor: pointer;
        color: #fff;
        font-size: 1.125rem;
        box-sizing: border-box;
        &.no{
          background: #fff;
          border: 2px solid #0880AE;
          color: #0880AE;
          font-weight: bold;
        }
    }
  }
}
  
</style>