
import { defineComponent } from "vue";

interface WebAppInterface {
  postMessage(msg: string): void;
}

declare var goToLogin: WebAppInterface;

export default defineComponent({
  props: {
    userid: String,
  },
  methods: {
    pwPageMove() {
      this.$emit("pwPageMove", {
        userid: this.userid,
      });
    },
    loginPageMove() {
      goToLogin.postMessage("success");
    },
  },
});
