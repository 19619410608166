
// import mixin from "@/mixin/mixin.js";
import { defineComponent } from "vue";


interface WebAppInterface {
  postMessage(msg: string): void;
}

declare var goToLogin: WebAppInterface;

export default defineComponent({
  // mixins: [mixin],
  props: {
    id: String,
  },
  methods:{
    loginPageMove(){
      goToLogin.postMessage('success');
    }
  }
});
