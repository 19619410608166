<template>
  <div class="terms_doc flex">
    <h1>우성소프트 주식회사 이용약관</h1>
    <div class="doc_body flex">
      <section>
        <h2>제 1조 (목적)</h2>
        <p>
          이 이용약관(이하 ‘약관’이라 합니다)은 ‘사용자’가 우성소프트
          주식회사(이하 ‘회사’라 합니다)에서 제공하는 ‘소프트웨어’를 이용하여
          ‘고객’의 정보를 등록하고 해당 정보를 ‘회사’의 클라우드 서버에
          보관하는데 있어 필요한 조건 및 절차에 관한 사항과 기타 필요한 사항을
          규정함을 목적으로 합니다.
        </p>
      </section>
      <section>
        <h2>제 2조 (용어의 정의)</h2>
        <li>
          <span>①</span>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
        </li>
        <ul>
          <li>
            <span>가.</span>
            ‘회사’라 함은 ‘사용자’에게 판매재고 관리프로그램을 제공하는
            우성소프트 주식회사를 말합니다
          </li>
          <li>
            <span>나.</span>
            ‘소프트웨어’라 함은 회사에서 유료로 설치 및 제공되는 모든 버전의
            프로그램들을 말합니다. ‘소프트웨어’에는 판매재고 관리프로그램인
            Win-Win Pro, Win-Win POS 등의 개별프로그램이 포함되며,
            개별프로그램은 추가되거나 제외될 수 있습니다.
          </li>
          <li>
            <span>다.</span>
            ‘사용자’라 함은 우성소프트 주식회사 소프트웨어 이용약관에 동의한 후
            ‘소프트웨어’를 설치하고 이용하는 판매상을 의미합니다.
          </li>
          <li>
            <span>라.</span>
            ‘고객’이라 함은 ‘사용자’로부터 농약,비료,영양제 등의 농자재 및
            기타물품을 구입한 구매자를 의미합니다.
          </li>
        </ul>
        <li>
          <span>②</span>이 약관에서 사용하는 용어 중 제1항에서 정하지 아니한
          것은, 저작자 또는 개발사의 소프트웨어 사용권 동의(EULA), 관계 법령에서
          정하는 바에 따르며, 그 외에는 일반 관례에 따릅니다.
        </li>
      </section>
      <section>
        <h2>제 3조 (약관의 명시와 효력 및 변경, 계약의 성립)</h2>
        <ul>
          <li>
            <span>①</span> 이 약관은 ‘회사’가 제공하는 ‘소프트웨어’의 서비스의
            이용 시에 ‘사용자’가 안내하고 ‘고객’이 동의를 함으로써 계약이 성립한
            것으로 보고 효력이 발생합니다.
          </li>
          <li>
            <span>②</span> ‘회사’는 관련 법령에 위배되지 않는 범위 내에서 이
            약관의 내용을 개정할 수 있으며, 개정약관은 제1항에 따라 ‘고객’이
            ‘사용자’를 통해 동의함으로써 효력이 발생합니다.
          </li>
          <li>
            <span>③</span>
            ‘고객’은 개정된 약관에 동의하지 않을 경우, 언제라도 ‘사용자’에게
            기록 삭제를 요구함으로써 이용계약을 해지할 수 있습니다.
          </li>
        </ul>
      </section>
      <section>
        <h2>제 4조 (회사의 의무)</h2>
        <ul>
          <li>
            <span>①</span>
            회사는 이 약관이 금지하거나 관련 법령 기타 공서양속에 반하는 행위를
            하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로
            서비스를 제공하는데 최선을 다합니다.
          </li>
          <li>
            <span>②</span>
            회사는 계속적이고 안정적인 ‘소프트웨어’ 제공을 위하여 설비 및
            소프트웨어에 장애가 생기거나 관련 자료가 멸실된 경우에는 부득이한
            사유가 없는 한 지체 없이 이를 수리 또는 복구하고 ‘소프트웨어’의
            최신성 및 안정성 향상과 ‘사용자’와 ‘고객’ 편의 도모를 위한
            업데이트를 자동으로 제공할 수 있습니다.
          </li>
        </ul>
      </section>
      <section>
        <h2>제 5조 (개인정보보호)</h2>
        <ul>
          <li>
            <span>①</span>
            회사는 고객의 개인정보 수집 시 서비스 제공을 위하여 필요한 범위에서
            최소한의 개인정보를 수집합니다.
          </li>
          <li>
            <span>②</span> 회사는 고객의 개인정보를 수집·이용하는 때에는
            ‘사용자’를 통해 당해 고객에게 그 목적을 고지하고 동의를 받습니다.
          </li>
          <li>
            <span>③</span> 회사는 수집된 개인정보를 목적 외의 용도로 이용할 수
            없습니다.
          </li>
          <li>
            <span>④</span> 고객은 언제든지 회사가 가지고 있는 자신의 개인정보에
            대해 열람 및 오류정정을 요구할 수 있으며 회사는 이에 대해 지체 없이
            필요한 조치를 취할 의무를 집니다. 고객이 오류의 정정을 요구한
            경우에는 회사는 그 오류를 정정할 때까지 당해 개인정보를 이용하지
            않습니다.
          </li>
          <li>
            <span>⑤</span> 회사는 개인정보 보호를 위하여 고객의 개인정보를
            취급하는 자를 최소한으로 제한하여야 하며 고객 개인정보의 분실, 도난,
            유출, 변조 등으로 인한 고객의 손해에 대하여 책임을 집니다.
          </li>
          <li>
            <span>⑥</span> 회사 또는 그로부터 개인정보를 제공받은 제3자는
            개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해
            개인정보를 지체 없이 파기합니다.
          </li>
          <li>
            <span>⑦</span> 회사는 개인정보의 수집·이용·제공에 관한 동의 란을
            미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의
            수집·이용·제공에 관한 고객의 동의 거절시 제한되는 서비스를
            구체적으로 명시하고, 필수 수집항목이 아닌 개인정보의
            수집·이용·제공에 관한 고객의 동의 거절을 이유로 서비스 제공을
            제한하거나 거절하지 않습니다.
          </li>
        </ul>
      </section>
      <section>
        <h2>제 6조 ( 분쟁해결 )</h2>
        <ul>
          <li>
            ① 회사는 고객이 제기하는 정당한 의견이나 불만을 반영하여 신속히 그
            사항을 처리하는데 최선을 다합니다.
          </li>
          <li>
            ② 회사는 고객으로부터 제출되는 불만사항 및 의견은 우선적으로 그
            사항을 처리하고, 신속한 처리가 곤란한 경우에는 고객에게 그 사유와
            처리일정을 즉시 통보해 드립니다.
          </li>
        </ul>
      </section>
      <section>
        <h2>제 7조 ( 재판권 및 준거법 )</h2>
        <ul>
          <li>
            ① 이 약관에 명시되지 않은 사항은 정보통신망 이용촉진 및 정보보호
            등에 관한 법률 등 관계법령과 상관행에 따릅니다.
          </li>
          <li>
            ② 이 약관에 명시되지 않은 개인정보보호 (제 5조) 내용은 우성소프트
            주식회사 개인정보처리방침에 따릅니다.
          </li>
          <li>
            ③ ‘소프트웨어’ 및 연동서비스 이용으로 발생한 분쟁에 대해 소송이
            제기되는 경우 민사소송법 상의 관할 법원으로 합니다.
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "./terms.scss";
</style>
