
import { defineComponent } from "vue";

interface WebAppInterface {
  postMessage(msg: string): void;
}

declare var goToLogin: WebAppInterface;

export default defineComponent({
  methods: {
    loginPageMove() {
      goToLogin.postMessage("success");
    },
  },
});
