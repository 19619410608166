import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import join from "../views/join.vue";
import Search from "@/views/idSearch.vue";
import joinResult from "@/components/join/joinResult.vue";
import pwChangeResult from "@/components/search/pwChangeResult.vue";
import docCheck from "@/components/doc_web/docCheck.vue";
import agreeItem_01 from "@/components/doc_web/agreeItem_01.vue";
import agreeItem_02 from "@/components/doc_web/agreeItem_02.vue";
import agreeItem_03 from "@/components/doc_web/agreeItem_03.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/join",
    name: "Join",
    component: join,
    props:true

  },
  {
    path: "/join/result/:id",
    name: "joinResult",
    props:true,
    component: joinResult, 
    
  }
  ,
  {
    path: "/join/pwChangeResult",
    name: "pwChangeResult",
    component: pwChangeResult, 
    props:true,
  }
  ,
  {
    path: "/search/:mode",
    name: "search",
    component: Search,
    props: true,
  },
  {
    path: "/doc_web/docCheck",
    name: "docCheck",
    component: docCheck,
    props: true,
  },
  {
    path: "/doc_web/agreeItem_01",
    name: "agreeItem_01",
    component: agreeItem_01,
    props: true,
  },
  {
    path: "/doc_web/agreeItem_02",
    name: "agreeItem_02",
    component: agreeItem_02,
    props: true,
  },
  {
    path: "/doc_web/agreeItem_03",
    name: "agreeItem_03",
    component: agreeItem_03,
    props: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
