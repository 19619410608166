<template>
  <div class="toast flex" v-if="mode > 0">
    <h2>{{ message(mode) }}</h2>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    mode: Number,
  },
  methods: {
    message(mode) {
      switch (mode) {
        case 1:
          return "인증 번호가 발송 되었습니다";
        case 2:
          return "잘못된 인증번호 입니다!";
        case 3:
          return "인증번호 발송에 실패 하였습니다.";
        case 4:
          return "이미 등록된 번호입니다.";
        case 5:
          return "가입되지 않은 번호입니다.";
        case 6:
          return "핸드폰 번호를 입력 해주세요.";
        case 7:
          return " ID중복체크가 되지 않았습니다.";
        case 8:
          return " 비밀번호의 형식에 맞지 않습니다.";
        case 9:
          return " 비밀번호가 일치하지 않습니다.";
        case 10:
          return " 핸드폰 인증을 해주세요.";
        case 11:
          return " 필수 항목을 체크 해주세요.";
        case 12:
          return "ID를 입력 해주세요.";
        case 13:
          return "회원가입이 완료 되었습니다.";
        case 14:
          return "회원가입에 실패 하였습니다.";
        case 15:
          return "소문자와 숫자만 입력 해주세요 (6~12글자 이내)";
        case 16:
          return "아이디를 찾지 못 했습니다";
        case 17:
          return "이미 등록 되어 있는 번호 입니다.";
        case 18:
          return "사업장번호를 조회해 업장을 선택 해주세요";
        case 19:
          return "사업자 번호를 입력해주세요";
        case 20:
          return "형식에 맞게 입력해주세요";
        case 21:
          return "비밀번호가 변경되지 않았습니다";
        case 22:
          return "핸드폰 번호 형식을 다시 확인해주세요.";
        case 23:
          return "인증번호는 숫자 6자리만 입력해주세요.";
        default:
          break;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
div.toast {
  position: absolute;
  width: 50%;
  max-width: 400px;
  padding: 0 20px;
  height: 120px;
  border-radius: 10px;
  text-align: center;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  h2 {
    color: #fff;
    line-height: 2rem;
    font-size: 1.125rem;
  }
}
</style>
