<template>
  <div class="join wrap">
    <transition name="fade">
      <toast :mode="toast" />
    </transition>
    <teleport to="#destination">
      <!-- <ConsentForm1 :modalOpen="modal" @modalClose="modalOpenAndClose1(0)" /> -->
      <ConsentForm1 :modalOpen="modal" @modalClose="modalOpenAndClose(0)" />
      <BNSearch
        :modalOpen="BNmodal"
        @modalClose="BNmodalOpenAndClose(0)"
        @chooseBusinessNum="getWNosw($event)"
      />
      <!-- <ConsentForm1 -->
      <!-- :modalOpen="modal2" -->
      <!-- @modalClose="modalOpenAndClose(0)" -->
      <!-- /> -->
    </teleport>
    <!-- <h1>{{platform}}</h1> -->
    <!-- <div class="HomeMoveBtn" @click="HomeMoveMethod()">&times;</div> -->
    <h1>신규 회원가입</h1>
    <ul class="form flex">
      <li class="flex">
        <div class="label">아이디</div>
        <div class="input_area flex">
          <div class="input_inner flex">
            <input
              type="text"
              v-model="idValue"
              ref="id"
              placeholder="ID를 입력해주세요."
            />
            <div class="btn flex" @click="idDuplicateCheck">중복확인</div>
            <div class="message" :style="{ color: idDupliCheckColor }">
              {{ idDupliMessage }}
            </div>
          </div>
        </div>
      </li>
      <li class="flex">
        <div class="label">비밀번호</div>
        <div class="input_area flex">
          <div class="input_inner flex">
            <div class="pwInputBox">
              <input
                :type="pwType"
                v-model="pwValue"
                ref="pw"
                placeholder="숫자, 영문, 특수문자 각 1자리 이상 입력해주세요. "
                @blur="pwValueCk"
              />
              <img
                class="pwImg"
                src="@/assets/show.png"
                v-if="!pwShowCheck"
                @click="pwShowChange()"
              />
              <img
                class="pwImg"
                src="@/assets/close.png"
                v-if="pwShowCheck"
                @click="pwShowChange()"
              />
            </div>
            <div
              class="message"
              v-if="!pwValueCheck"
              :style="{ color: '#FF6161' }"
            >
              <span
                >* 영문, 숫자, 특수문자 각 1자리 이상 입력해주세요.<br
              /></span>
              <span> &nbsp;&nbsp;( 8~20자 이내 )</span>

              <br />
              <span>* 사용 가능한 특수기호: ! @ # $ % & *</span>
            </div>
          </div>
        </div>
      </li>
      <li class="flex">
        <div class="label">비밀번호 확인</div>
        <div class="input_area flex">
          <div class="input_inner flex">
            <div class="pwInputBox">
              <input
                :type="pwSameType"
                v-model="pwValueConfirmValue"
                ref="pwCk"
                placeholder="패스워드를 한번더 입력해 주세요"
                @blur="pwValueConfirmCk"
              />
              <img
                class="pwImg"
                src="@/assets/show.png"
                v-if="!pwSameShowCheck"
                @click="pwSameShowChange()"
              />
              <img
                class="pwImg"
                src="@/assets/close.png"
                v-if="pwSameShowCheck"
                @click="pwSameShowChange()"
              />
            </div>
            <div
              class="message"
              v-if="!pwValueConfirmCheck"
              :style="{ color: '#FF6161' }"
            >
              비밀번호가 일치하지 않습니다.
            </div>
          </div>
        </div>
      </li>
      <phoneCheck
        @phoneCheckResult="getPhoneResult($event)"
        @hpDupliCk="hpDupliCk($event)"
        :modeNum="1"
        :platform="platform"
      />
      <li class="flex">
        <div class="label">사업자 번호</div>
        <div class="input_area flex">
          <div class="input_inner flex">
            <input
              type="text"
              ref="bnCk"
              v-model="resultBN"
              @click="BNmodalOpenAndClose(1)"
              placeholder="사업자등록번호 입력"
              readonly
            />
          </div>
        </div>
      </li>
      <li class="flex terms">
        <div class="label">이용약관</div>
        <div class="flex out">
          <div class="terms flex">
            <input type="checkbox" v-model="consentForm1" />
            <div class="content content1">(필수) 이용 약관</div>
            <div class="terms_view" @click="modalOpenAndClose(1)">
              자세히 보기
            </div>
          </div>
          <div class="terms flex">
            <input type="checkbox" v-model="consentForm2" />
            <div class="content content2">(필수) 개인정보 처리방침</div>
            <div class="terms_view" @click="modalOpenAndClose(2)">
              자세히 보기
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="btn" @click="join()">회원 가입</div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import axios from "axios";
// import router from "vue-router";
import phoneCheck from "@/components/checkInputs/phoneCheck.vue";
import ConsentForm1 from "@/components/join/ConsentForm1.vue";
import BNSearch from "@/components/join/BNSearch.vue";
import ConsentForm2 from "@/components/join/ConsentForm2.vue";
import toast from "@/components/alerts/toast.vue";

export default defineComponent({

  components: {
    phoneCheck,
    ConsentForm1,
    ConsentForm2,
    toast,
    BNSearch,
    // router,
  },
  data() {
    return {
      platform: '',
      
      toast: 0, //
      //  v-model Values start
      idValue: "", //id 입력값
      pwValue: "", // 비밀번호 입력값
      pwValueConfirmValue: "", // 비밀번호 확인 입력값
      userCellPhoneNumber: "", // 고객이 입력한 핸드폰번호값
      userPhoneAuthValue: "", //고객이 입력한 인증번호값
      businessNumber: "",
      // v-model Values end

      pwShowCheck: false,
      pwSameShowCheck: false,
      pwType: "password",
      pwSameType: "password",

      idDupliMessage: "",
      idDupliCheckColor: "",

      idDupliCheckBoolean: Boolean, //id 중복확인
      pwValueCheck: Boolean, //pw 유효성검사
      pwValueConfirmCheck: Boolean, //pw 재검사
      userPhoneAuthCheck: Boolean, //핸드폰 인증확인 구분
      hpDupliCkBool: false, // 핸드폰 등록여부확인
      WNosw: "", // 선택한 사업자의 w_nosw 값
      resultBN: "", //선택한 사업자번호
      wSangho: "", //선택한 상호명
      DBName: "",

      consentForm1: false,
      consentForm2: false,

      modal: 0,
      BNmodal: 0,
    };
  },
  watch: {
    idValue() {
      this.idDupliMessage = "";
      this.idDupliCheckBoolean = false;
      this.idDupliCheckColor = "";
      this.idValue = this.idValue.toLowerCase();
      this.idValue = this.idValue.replace(/ /gi, "");
    },
  },
  created() {
    this.platform = this.$route.query.platform;
  },
  methods: {
    toastView(toastNumber) {
      this.toast = toastNumber;
      setTimeout(() => {
        this.toast = 0;
      }, 2000);
    },
    pwValueCk() {
      const pwRegex = new RegExp(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/
      );

      const pwValueRegex = pwRegex.test(this.pwValue);
      if (pwValueRegex) {
        this.pwValueCheck = true;
      } else {
        this.pwValueCheck = false;
      }

      this.pwAndPwConfirmSameCk();
    },
    pwValueConfirmCk() {
      this.pwAndPwConfirmSameCk();
    },
    pwAndPwConfirmSameCk() {
      if (this.pwValueConfirmValue === this.pwValue) {
        this.pwValueConfirmCheck = true;
      } else {
        this.pwValueConfirmCheck = false;
      }
    },
    idDuplicateCheck() {
      if (this.idValue === "") {
        this.toastView(12); // ID를 입력해주세요
        this.idDupliCheckBoolean = false;
        return;
      }
      if (!/^[0-9a-z]{6,12}$/.test(this.idValue)) {
        this.toastView(15); // 5~20 자 이내로 ID를작성해주세요.
        this.idDupliCheckBoolean = false;
        return;
      }
      axios
        .get(`${this.API}/auth/duplicate/userid/${this.idValue}`,{
          params:{platform:this.platform}
          })
        .then((res) => {
          if (res.status === 200) {
            const dupliData = res.data.msg;
            if (dupliData === "이미 등록된 아이디입니다.") {
              this.idDupliMessage = dupliData;
              this.idDupliCheckBoolean = false;
              this.idDupliCheckColor = "#FF6161";
            } else if (dupliData === "등록 가능한 아이디입니다.") {
              this.idDupliMessage = dupliData;
              this.idDupliCheckBoolean = true;
              this.idDupliCheckColor = "blue";
            }
          } else {
            this.toastView(7); // ID중복체크가 되지 않았습니다.
          }
        });
    },
    getPhoneResult(value) {
      this.userPhoneAuthCheck = value.userPhoneAuthCheck;
      this.userCellPhoneNumber = value.userCellPhoneNumber;
    },
    hpDupliCk(value) {
      this.hpDupliCkBool = value.hpDupliCkBool;
    },
    pwShowChange() {
      this.pwShowCheck = !this.pwShowCheck;
      if (this.pwShowCheck) {
        this.pwType = "text";
      } else {
        this.pwType = "password";
      }
    },
    pwSameShowChange() {
      this.pwSameShowCheck = !this.pwSameShowCheck;
      if (this.pwSameShowCheck) {
        this.pwSameType = "text";
      } else {
        this.pwSameType = "password";
      }
    },
    join() {
      // console.log(this.idDupliCheckBoolean);
      if (this.idDupliCheckBoolean !== true) {
        this.toastView(7); // ID중복체크가 되지 않았습니다.
        this.$refs.id.focus();
        return;
      } else if (this.pwValueCheck !== true) {
        this.toastView(8); // 비밀번호가 유효하지 않습니다.
        this.$refs.pw.focus();

        return;
      } else if (this.pwValueConfirmCheck !== true) {
        this.toastView(9); // 비밀번호가 일치하지 않습니다.
        this.$refs.pwCk.focus();

        return;
      } else if (this.userPhoneAuthCheck !== true) {
        this.toastView(10); // 핸드폰 인증이 진행되지 않았습니다.
        return;
      } else if (this.WNosw === "") {
        this.toastView(18); // 사업장번호를 조회해 업장을 선택 해주세요
        const _this = this;
        setTimeout(() => {
          _this.BNmodalOpenAndClose(1);
        }, 2000);

        return;
      } else if (this.consentForm1 !== true || this.consentForm2 !== true) {
        this.toastView(11); // 필수항목을 체크해주세요.
        return;
      } else if (this.hpDupliCkBool !== true) {
        this.toastView(17); // 이미 등록되어있는 번호입니다
        return;
      }
      const data = {
        w_nosw: this.WNosw,
        id: this.idValue,
        password: this.pwValue,
        cellphone: this.userCellPhoneNumber,
        cp_cert: 1,
        email: "",
        email_cert: 0,
        platform:this.platform
      };
      axios.post(`${this.API}/auth/join`, data).then(async (res) => {
        if (res.status === 200) {
          const ownerHp = await this.DBNameHpNumberSearch(this.WNosw);
          // this.sendJoinMsg(ownerHp, data.id);
          //
          this.sendJoinMsg(data.cellphone, ownerHp, data.id);

          this.$router.push({
            name: "joinResult",
            params: { id: data.id },
          });
        } else {
          // 회원가입이 실패했을 경우
          this.toastView(14);
        }
      });
    },

    sendJoinMsg(hp, joinHp, id) {
      const data = {
        templateId: "joinSuccess",
        recipientList: [
          {
            recipientNo: `${hp}`,
            countryCode: 82,
            templateParameter: {
              wSangho: `${this.wSangho}`,
              hp: `${hp}`,
              id: `${id}`,
            },
          },
          {
            recipientNo: `${joinHp}`,
            countryCode: 82,
            templateParameter: {
              wSangho: `${this.wSangho}`,
              hp: `${hp}`,
              id: `${id}`,
            },
          },
        ],
      };

      axios.post(`https://sms.wssw.kr/mms`, data).then((res) => {
        if (res.status === 200) {
          //완료페이지로감
        } else {
          console.error(error);
        }
      });
    },
    async DBNameHpNumberSearch(WNosw) {
      const result = await axios
        .get(`${this.API}/auth/find/hp/${WNosw}`)
        .then((res) => {
          if (res.status === 200) {
            // console.log(res.data.w_hp);
            return res.data.w_hp;
          } else {
            // 핸드폰 검색 에러날 시
          }
        });
      return result;
    },
    modalOpenAndClose(check) {
      this.modal = check;
    },

    BNmodalOpenAndClose(check) {
      this.BNmodal = check;
    },
    async getWNosw(value) {
      this.WNosw = value.WNosw;
      this.resultBN = value.resultBN;
      this.wSangho = value.wSangho;
      this.DBName = value.DBName;
      this.BNmodalOpenAndClose(0);
    },
    
  },
});
</script>

<style lang="scss" scoped>
$phone: "only screen and (max-width : 769px)";

div.join {
  padding: 50px 0;
  h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 50px;
    @media #{$phone} {
      font-size: 1.5rem;
    }
  }
  div.btn {
    /* margin: 20px 0; */
    width: 100%;
    max-width: 460px;
    background-color: #a6a6a6;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
  }
  .pwInputBox {
    display: flex;
    position: relative;
    max-width: 360px;
    min-width: 360px;
    width: 100%;
    height: 100%;
    flex: 1;
    background: #fbfbfb;
    border-bottom: 2px solid #d0d0d0;
    justify-content: end;
    align-items: center;
    input {
      border-bottom: 0 !important;
    }
    .pwImg {
      position: absolute;
      margin-right: 20px;
    }
  }
  .message span {
    display: inline-block;
    padding: 0px 0px 3px 0px;
    font-size: 15px;
     @media #{$phone} {
      font-size: 13px;
    }
  }

}
</style>
