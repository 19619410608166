<template>
  <div class="pw_search wrap">
    <transition name="fade">
      <toast :mode="toast" />
    </transition>
    <h1>비밀번호를 재설정 해주세요</h1>
    <!-- {{ userid }} -->
    <p>
      숫자, 영문, 특수문자 각 1자리 이상 입력해주세요. <br />(최소 8글자, 최대
      20글자)
    </p>
    <ul class="form flex">
      <li class="flex">
        <div class="label">비밀번호</div>
        <div class="input_area flex">
          <div class="input_inner flex">
            <div class="pwInputBox">
              <input
                :type="pwType"
                v-model="updatePw"
                @blur="updatePwCk"
                placeholder="숫자, 영문, 특수문자 각 1자리 이상 입력해주세요. "
              />
              <img
                class="pwImg"
                src="@/assets/show.png"
                v-if="!pwShowCheck"
                @click="pwShowChange()"
              />
              <img
                class="pwImg"
                src="@/assets/close.png"
                v-if="pwShowCheck"
                @click="pwShowChange()"
              />
            </div>
            <div class="message" v-if="!pwCk" :style="{ color: '#FF6161' }">
              숫자, 영문, 특수문자 각 1자리 이상 입력해주세요. <br />(최소
              8글자, 최대 20글자)
            </div>
          </div>
        </div>
      </li>
      <li class="flex">
        <div class="label">비밀번호 확인</div>
        <div class="input_area flex">
          <div class="input_inner flex">
            <div class="pwInputBox">
              <input
                :type="pwSameType"
                v-model="CheckUpdatePw"
                @blur="pwSameCheck"
                placeholder="패스워드를 한번더 입력해 주세요"
              />
              <img
                class="pwImg"
                src="@/assets/show.png"
                v-if="!pwSameShowCheck"
                @click="pwSameShowChange()"
              />
              <img
                class="pwImg"
                src="@/assets/close.png"
                v-if="pwSameShowCheck"
                @click="pwSameShowChange()"
              />
            </div>
            <div
              class="message"
              v-if="!pwUpdateCk"
              :style="{ color: '#FF6161' }"
            >
              비밀번호가 일치하지 않습니다.
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="btn" @click="putPwUpdate" :style="{ background: colorChange }">
      완료
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import toast from "@/components/alerts/toast.vue";

export default defineComponent({
  components: { toast },
  props: {
    userid: String,
    platform: String
  },
  data() {
    return {
      pwShowCheck: false,
      pwSameShowCheck: false,
      pwType: "password",
      pwSameType: "password",

      colorChange: "#dfe2e7",
      toast: 0,
      updatePw: "",
      CheckUpdatePw: "",
      pwCk: Boolean,
      pwUpdateCk: Boolean,
    };
  },
  methods: {
    toastView(toastNumber) {
      this.toast = toastNumber;
      setTimeout(() => {
        this.toast = 0;
      }, 2000);
    },
    updatePwCk() {
      const pwRegex = new RegExp(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/
      );

      const pwValueRegex = pwRegex.test(this.updatePw);
      if (pwValueRegex) {
        this.pwCk = true;
      } else {
        this.pwCk = false;
      }

      this.pwSameCheck();
    },
    pwSameCheck() {
      if (this.updatePw === this.CheckUpdatePw) {
        this.pwUpdateCk = true;
      } else {
        this.pwUpdateCk = false;
      }
    },
    putPwUpdate() {
      if (this.pwCk !== true) {
        this.toastView(8); // 비밀번호의 형식에 맞지않습니다
        return;
      } else if (this.pwUpdateCk !== true) {
        this.toastView(9); // 비밀번호가 일치하지 않습니다.
        return;
      }
      const params = {
        id: this.userid,
        password: this.updatePw,
        platform : this.platform
      };


      axios
        .put(`${this.API}/auth/update/password`, {}, { params })
        .then((res) => {
          if (res.status === 200) {
            this.updatePw = "";
            this.CheckUpdatePw = "";
            this.$router.push({
              name: "pwChangeResult",
            });
          } else {
            this.toastView(21);
            return;
          }
        })
        .catch((err) => {});
    },
    pwShowChange() {
      this.pwShowCheck = !this.pwShowCheck;
      if (this.pwShowCheck) {
        this.pwType = "text";
      } else {
        this.pwType = "password";
      }
    },
    pwSameShowChange() {
      this.pwSameShowCheck = !this.pwSameShowCheck;
      if (this.pwSameShowCheck) {
        this.pwSameType = "text";
      } else {
        this.pwSameType = "password";
      }
    },
  },
  watch: {
    CheckUpdatePw() {
      const pwRegex = new RegExp(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/
      );
      const pwValueRegex = pwRegex.test(this.updatePw);

      if (this.updatePw === this.CheckUpdatePw && pwValueRegex) {
        this.colorChange = "#0880ae";
      } else {
        this.colorChange = "#dfe2e7";
      }
    },
    updatePw() {
      const pwRegex = new RegExp(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/
      );

      const pwValueRegex = pwRegex.test(this.updatePw);
      if (this.updatePw === this.CheckUpdatePw && pwValueRegex) {
        this.colorChange = "#0880ae";
      } else {
        this.colorChange = "#dfe2e7";
      }
    },
  },
});
</script>

<style lang="scss" scoped>
div.pw_search {
  div.search {
    width: 100%;
  }
  h1 {
    font-size: 1.875rem;
    font-weight: bold;
    text-align: center;
    line-height: 2.5rem;
  }
  p {
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #7c7b7b;
    margin: 20px 0;
    text-align: center;
  }
  ul.form {
    max-width: 750px;
    margin: 50px auto;
    gap: 60px;
    li{
      width:90%;
    }
    .input_area{
      max-width: 360px;
    
      .input_inner {
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        .pwInputBox {
          height: 100%;
          width:100%;
          position: relative;
          display: flex;
          flex-direction: row;
          align-content: center;
          justify-content: flex-end;
          align-items: center;
          input {
            margin-bottom: 10px;
            // max-width: 450px;
            // min-width: 450px;
          }

          .pwImg {
            position: absolute;
            right: 5px;
            bottom:30%;
          }
        }
        .message {
          margin: 0px;
        }
      }
    }
  }
  div.btn {
    width: 100%;
    max-width: 460px;
    background-color: #dfe2e7;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    color: #fff;
    font-size: 1.125rem;
    margin: 0px auto;
  }

}
</style>
