<template>
  <div class="flex out">
    <transition name="fade">
      <toast :mode="toast" />
    </transition>
    <li class="flex">
      <div class="label">휴대전화 번호</div>
      <div class="input_area flex">
        <div class="input_inner flex">
          <input
            type="text"
            v-model="userCellPhoneNumber"
            ref="hpNumber"
            placeholder="01012341234 / - 없이 입력"
            @blur="hpValidation"
          />
          <div class="btn flex" @click="authMethod(modeNum)">
            {{ authMessage }}
          </div>
        </div>
      </div>
    </li>
    <li class="flex">
      <div class="label">인증번호</div>
      <div class="input_area flex">
        <div class="input_inner flex">
          <div class="timer_box">
            <input
              type="text"
              v-model="userPhoneAuthValue"
              placeholder="인증번호 6자리 입력"
            />
            <span v-if="TimerShow" class="timer"> {{ TimerStr }}</span>
          </div>
          <div class="btn flex" @click="userPhoneNumberAuthCheck()">
            인증 확인
          </div>
          <div
            class="message"
            v-if="true"
            :style="{ color: userPhoneAuthCheckColor }"
          >
            {{ userPhoneAuthCheckMessage }}
          </div>
        </div>
      </div>
    </li>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import toast from "@/components/alerts/toast.vue";
import axios from "axios";
export default defineComponent({
  components: { toast },
  props: {
    modeNum: Number,
    platform:String
  },
  data() {
    return {
      toast: 0, // 1 전송 2 인증번호 틀림
      authMessage: "인증", //보내기 전 div 값
      userCellPhoneNumber: "", //고객이 입력한 핸드폰번호

      // userCellPhoneAuthSendCode: "", //보낸 인증번호
      authIdx: "", //보낸 인증번호에 대한 idx정보
      // authCode: "", //보낸 인증번호에 대한 idx정보
      hpChangeCk: false, //핸드폰 번호에 변화가 있는지 체크

      userPhoneAuthValue: "", //고객이 입력한 인증번호
      userPhoneAuthCheckMessage: "",
      userPhoneAuthCheckColor: "",
      AuthCount: 0,

      TimeCounter: 180,
      TimerStr: "03:00",
      Timer: null,
      TimerShow: false,
    };
  },
  
  methods: {
    //  auth 만료 시간 관련 메서드
    mounted() {
      //문자발송성공시 호출
      if (this.Timer != null) {
        this.timerStop(this.Timer);
        this.Timer = null;
      }
      this.Timer = this.timerStart();
    },

    timerStart() {
      this.TimeCounter = 180;
      const interval = setInterval(() => {
        this.TimeCounter--;
        this.TimerStr = this.timeTypeChange();
        if (this.TimeCounter <= 0) this.timerStop(interval);
      }, 1000);
      return interval;
    },
    timerStop(Timer) {
      clearInterval(Timer);
      this.TimeCounter = 0;
    },

    timeTypeChange() {
      this.TimeCounter;
      let time = this.TimeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return (
        minutes.toString().padStart(2, "0") +
        ":" +
        secondes.toString().padStart(2, "0")
      );
    },
    // auth 만료시간 관련 메서드
    authMethod(modeNum) {
      // console.log(modeNum);
      switch (modeNum) {
        case 1:
          this.authNumberSend();
          break;
        case 2:
          this.idFindAuthSend();
          break;
        default:
          console.log("modeNum이 올바르지 않습니다");
          break;
      }
    },
    toastView(toastNumber) {
      this.toast = toastNumber;
      setTimeout(() => {
        this.toast = 0;
      }, 2000);
    },
    async phoneCheck(userPhoneNumber) {
      try {
        if (this.userCellPhoneNumber === "") {
          this.toastView(6); // 핸드폰 번호를 입력하세요.
          this.$refs.hpNumber.focus();
          return;
        } else {
          const phonCheck = await axios.get(
            `${this.API}/auth/duplicate/cellphone/${userPhoneNumber}`
            ,{params:{platform:this.platform}}
          );

          console.log(parmas);
          switch (phonCheck.data.msg) {
            case "이미 등록된 휴대폰번호 입니다.":
              return 1;
            case "등록 가능한 휴대폰번호 입니다.":
              return 2;
            default:
              return 0;
          }
        }
      } catch {
        return false;
      }
    },
    async sendAuth(userPhoneNumber) {
      if (this.userCellPhoneNumber === "") {
        this.toastView(6); //핸드폰 번호를 입력해주세요.
        return;
      }
      if (
        /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/.test(this.userCellPhoneNumber) ||
        /^[0-9]{11}$/.test(this.userCellPhoneNumber)
      ) {
      } else {
        this.toastView(22);
        return;
      }
      this.TimerShow = true;
      axios
        .post(`https://sms.wssw.kr/sms/auth/${userPhoneNumber}`)
        .then((res) => {
          if (res.status === 200) {
            this.AuthCount += 1;
            this.mounted();

            this.toastView(1);
            // this.userCellPhoneAuthSendCode = res.data.authCode;
            this.authIdx = res.data.insertAuth[0].idx;
            this.hpChangeCk = true;
          } else {
            this.toastView(3);
          }
        });
      this.authMessage = "재발급";
    },
    async authNumberSend() {
      // 회원가입 핸드폰
      // console.log(this.userCellPhoneNumber)
      const userPhoneNumber = this.userCellPhoneNumber;
      await this.sendAuth(userPhoneNumber);
      const result = await this.phoneCheck(userPhoneNumber);
      switch (result) {
        case 1:
          // this.toastView(4);
          this.$emit("hpDupliCk", {
            hpDupliCkBool: false,
          });
          break;
        case 2:
          this.$emit("hpDupliCk", {
            hpDupliCkBool: true,
          });

          break;
        default:
          break;
      }
    },
    async idFindAuthSend() {
      // id 찾기 인증번호 전송
      const userPhoneNumber = this.userCellPhoneNumber.replace(/\-/gi,'');
      await this.sendAuth(userPhoneNumber);

      const result = await this.phoneCheck(userPhoneNumber);
      switch (result) {
        case 1:
          // this.toastView(4);
          this.$emit("userHpFind", {
            userHpFind: true,
          });
          break;
        case 2:
          this.$emit("userHpFind", {
            userHpFind: false,
          });

          break;
        default:
          break;
      }
    },

    userPhoneNumberAuthCheck() {
      if (this.userPhoneAuthValue === "") {
        this.userPhoneAuthCheck = false;
        this.userPhoneAuthCheckMessage = "인증번호를 입력해주세요";
        this.userPhoneAuthCheckColor = "#FF6161";
        return;
      }
      if (!/^[0-9]{6}$/.test(this.userPhoneAuthValue)) {
        this.toastView(23);
        return;
      }
      if (this.hpChangeCk === false) {
        // 재인증을 해야 할 때.
        return;
      }
      const params = {
        auth_code: this.userPhoneAuthValue,
        auth_time: "180",
      };
      axios
        .put(`https://sms.wssw.kr/sms/auth/${this.authIdx}`, {}, { params })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.auth === 1) {
              this.userPhoneAuthCheck = true;

              this.userPhoneAuthCheckMessage = "인증이 완료되었습니다";
              this.userPhoneAuthCheckColor = "blue";
              this.$emit("phoneCheckResult", {
                userPhoneAuthCheck: true,
                userCellPhoneNumber: this.userCellPhoneNumber,
              });
              this.timerStop(this.Timer);
            } else {
              this.userPhoneAuthCheck = false;
              this.userPhoneAuthCheckMessage = res.data.msg;
              this.userPhoneAuthCheckColor = "#FF6161";
              this.$emit("phoneCheckResult", { userPhoneAuthCheck: false });
            }
          } else {
            console.error(error);
          }
        });
    },
  },
  watch: {
    userCellPhoneNumber() {
      this.$emit("phoneCheckResult", { userPhoneAuthCheck: false });
      if (this.AuthCount >= 1) {
        this.authMessage = "인증";
        this.userCellPhoneAuthSendCode = "";
        this.userPhoneAuthCheckMessage = "재인증을 해주세요";
        this.userPhoneAuthCheckColor = "#FF6161";
        this.userPhoneAuthValue = "";
        this.hpChangeCk = false; //인증을 보냈을 때 true 로 만들어주고 이후에 움직임이 있다면 false로 만들어서 전송을 제한
        this.timerStop(this.Timer);
        this.TimerShow = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.timer_box {
  display: flex;
  position: relative;
  max-width: 360px;
  width: 80%;
  height: 100%;
  flex: 1;
  background: #fbfbfb;
  border-bottom: 2px solid #d0d0d0;
  justify-content: center;
  align-items: center;
  input {
    border-bottom: 0 !important;
  }
}
.timer {
  padding: 0 10px;
  position: absolute;
  right: 0px;
}
</style>
