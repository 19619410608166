<template>
  <div class="terms_doc flex">
    <h1>우성소프트 주식회사 개인정보처리방침</h1>
    <div class="doc_body flex">
      <section>
        <p>
          우성소프트 주식회사(이하 “회사”라 한다)는 고객의 정보를 소중하게
          생각하며, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」등
          관련법규를 준수하기 위하여 [개인정보처리방침]을 제정하고 이를 준수하고
          있습니다. 회사의 개인정보 처리방침은 관련 법률 및 지침의 변경이나 회사
          내부 운영방침의 변경 등으로 인하여 개정될 수 있습니다.
          [개인정보처리방침]이 변경될 경우 변경사항은 안내공지를 통해서
          알립니다. 변경사항에 의문이 있으실 경우에는 언제라도 담당부서에
          문의하시어 확인하실 수 있습니다.
        </p>
      </section>
      <section>
        <h2>제 1조 (총칙)</h2>
        <p>
          회사는 고객의 개인정보보호를 매우 중요시하며, 「정보통신망 이용촉진 및
          정보보호 등에 관한 법률」을 준수하고 있습니다. 개인정보처리방침을
          통하여 고객이 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고
          있으며 개인 정보보호를 위해 어떠한 조치가 취해지고 있는지
          알려드립니다. 개인정보처리방침의 지속적인 개선을 위하여 개인정
          보처리방침을 개정하는데 필요한 절차를 정하고 있습니다.
        </p>
      </section>
      <section>
        <h2>제2조 (개인정보의 수집 항목 및 이용 목적)</h2>
        <p>
          회사는 다양하고 편리한 서비스를 제공하기 위하여 아래와 같은 목적으로
          개인정보를 수집 및 이용하고 있습니다. 회사는 다음과 같은 목적 이외에는
          개인정보를 이용하지 않으며, 회사에서 제공하는 서비스의 목적이 변경되는
          경우 별도의 동의를 받는 등 필요한 조치를 취합니다.
        </p>
        <p class="flex">
          <span>①. </span>
          고객의 영수증, 세금계산서, 거래명세표 등 각종 증빙서류 발급 및 영세율
          반영을 위해 수집되는 정보
        </p>

        <ul class="tab">
          <li class="tab">
            <span>가.</span>
            필수 항목
          </li>
          <li>
            <span>-</span> 개인: 성명, 주소, 이메일 주소, 연락처, 생년월일,
            농업경영체등록정보
          </li>
          <li>
            <span>-</span> 법인/사업자: 회사명, 대표자, 사업자등록정보, 주소,
            이메일 주소, 연락처, 농업경영체등록정보
          </li>
          <li><span>-</span> 매출정보: 구매내역, 입금내역, 미수금내역</li>
          <li>
            <span>-</span> 결제정보: 신용카드 결제(카드사명, 카드번호,
            유효기간), 현금영수증 발급시 (휴대폰 번호)
          </li>
        </ul>
        <ul class="tab">
          <li class="tab">
            <span>나.</span>
            선택항목
          </li>
          <li><span>-</span> 고객정보: 주요작물, 전자싸인, 마일리지</li>
        </ul>
        <ul class="tab">
          <li class="tab">
            <span>다.</span>
            방법: 우성소프트 소프트웨어
          </li>
        </ul>
        <p class="flex">
          <span>②. </span>
          우성소프트 소프트웨어 이용과정에서 생성되는 정보
        </p>
        <p>
          회사는 사용자가 우성소프트 소프트웨어를 이용하는 과정에서 아래와 같은
          정보를 수집할 수 있습니다. 고객 방문일시, 처방기록, 구매기록 통계
          분석, 농약안전정보시스템으로 전송된 농약구매기록 등 회사는 합법적으로
          공개된 정보 및 고객의 구매기록과 데이터와 같은 정보를 수집하여
          고객에게 보다 좋은 서비스 를제공할 수 있도록 분석 및 활용을 하고
          있습니다. 구체적으로, 고객의 관심에 맞춘 서비스를 우선적으로 제공하 고
          마케팅적으로 고객의 관심분야에 맞는 광고를 제공하거나, 이벤트
          참여기회를 제공할 수 있습니다
        </p>
      </section>
      <section>
        <h2>제3조 (개인정보의 수집 방법)</h2>
        <p>
          회사는 원활한 서비스 제공을 위해 우성소프트 소프트웨어를 이용하는
          사용자가 고객에게 그 목적을 고지하고 개인 정보를 수집합니다.
        </p>
      </section>
      <section>
        <h2>제4조 (개인정보의 제3자 제공)</h2>
        <ul>
          <li>
            <span>①</span> 회사는 고객의 개인정보를 제2조(개인정보의 수집 항목
            및 이용 목적)에서 고지한 범위 내에서 사용하며, 동 범 위를 초과하여
            이용하거나 제3자에게 제공하지 않습니다. 다만, 다음 사항은 예외로
            합니다.
          </li>
          <ul class="tab">
            <li>
              <span>1)</span> 수사목적으로 관계법률에서 정한 절차와 방법에 따라
              수사기관이 개인정보 제공을 요구하는 경우
            </li>
            <li>
              <span>2)</span> 통계작성, 학술연구나 시장조사 등을 위하여 특정
              개인을 식별할 수 없는 형태로 협력사나 연구단체 등에 제공하는 경우
            </li>
            <li>
              <span>3)</span> 기타 관계법률의 규정에 따른 요청이 있는 경우
            </li>
            <li>
              <span>4)</span> 영업의 양도·합병 등이 있는 경우 (단, 회사는 영업의
              양도 등에 관한 사유가 발생하여 고객의 개인정보 이전이 필요한
              경우에는 관계법률에서 규정한 절차와 방법에 따라 개인정보 이전에
              관한 사실 등을 사전에 고지하며 고객 에게는 개인정보 이전에 관한
              동의를 받기 위해 사전에 알림고지를 하거나 철회권을 부여합니다.
            </li>
          </ul>
          <li>
            <span>②</span> 회사는 개인정보를 제3자에게 제공할 수 있습니다.
            고객은 회사가 기존서비스의 개선 및 고객을 위한 보다 나은 서비스의
            개발을 목적으로 수집된 데이터를 활용하거나 제3자에게 제공하여
            활용하는 것에 동의합니다. 또한, 고객은 개인정보의 제3자 제공에
            대하여 동의를 거부할 수 있고, 언제든지 제3자 제공 동의를 철회할 수
            있습니다. 단, 제3자 제공 동의를 거부 또는 철회할 경우 “회사”가
            제공하는 서비스 일부가 제한될 수 있습니다.
          </li>
        </ul>
        <!-- <table>
          <thead>
            <tr>
              <td>제공받는자</td>
              <td>개인정보 이용목적</td>
              <td>제공하는 개인정보의 항목</td>
              <td>보유 및 이용기간</td>
            </tr>
          </thead>
          <tr>
            <td>농촌진흥청</td>
            <td>농약등의 구매자 기록 및 보존, 농약안전정보시스템 제공용</td>
            <td>성명, 주소, 연락처</td>
            <td>사용자가 제3자 제공동의를 철회하는 때까지</td>
          </tr>
        </table> -->
      </section>
      <section>
        <h2>제5조 (개인정보의 보유 및 이용기간)</h2>
        <ul>
          <li>
            <span>①</span>
            원칙적으로 사용자가 소프트웨어 사용계약을 종료하거나 고객의 데이터
            삭제 요청이 있는 경우, 고객의 개인정보 는 지체없이 파기됩니다. 다만,
            「전자상거래 등에서의 소비자 보호에 관한 법률」 등 관계법률에 의하여
            보존할 필요 가 있는 경우에는 그 기간 동안 보존합니다. 이 경우,
            회사는 보관하는 정보를 그 보관 목적으로만 이용하며, 보존기 간은
            아래와 같습니다. (전자상거래 등에서의 소비자 보호에 관한 법률)
          </li>
          <ul class="tab">
            <li><span>●</span> 계약 또는 청약철회 등에 관한 기록: 5년</li>
            <li><span>●</span> 대금결제 및 재화 등의 공급에 관한 기록: 5년</li>
            <li><span>●</span> 소비자의 불만 또는 분쟁처리에 관한 기록 3년</li>
            <li><span>●</span> 국세기본법 거래내역 관련 정보: 5년</li>
            <li><span>●</span> 통신비밀보호법 통신사실 확인자료 기록: 3개월</li>
          </ul>
          <li>
            <span>※</span> 기타 법률에서 규정한 의무를 준수하기 위해 처리한
            개인정보 이력은 그 책임을 입증하기 위한 기간 동안 보관할 수
            있습니다.
          </li>
          <li>
            <span>②</span> 보유기간을 미리 공지하고 그 보유기간이 경과하지
            아니한 경우와 개별적으로 동의를 받은 경우에는 약정한 기간 동안
            보유합니다.
          </li>
        </ul>
      </section>
      <section>
        <h2>제6조 (개인정보의 파기절차 및 방법)</h2>
        <ul>
          <li>
            <span>①</span> 회사는 원칙적으로 사용자가 소프트웨어 사용계약을
            종료하거나 고객의 데이터 삭제 요청이 있는 경우, 고객의 개인정보를
            지체없이 파기합니다. 구체적인 파기절차, 파기시점, 파기방법은 다음과
            같습니다.
          </li>
          <ul class="tab">
            <li>
              <span>1)</span> 파기절차 사용자가 우성소프트 소프트웨어를 이용하여
              입력한 정보는 사용자와 회사간의 소프트웨어 사용계약이 종료되거나
              고객의 데이터 삭제 요청이 있는 경우, 내부 방침 및 기타 관련 법령에
              정한 기간동안 저장된 후 파기합니다. 동 개인정보는 법률에 의한
              경우를 제외하고, 동의 받은 보유 목적 이외의 다른 목적으로 이용하지
              않습니다.
            </li>
            <li>
              <span>2)</span>
              파기시점
            </li>
            <ul class="tab">
              <li>
                <span>-</span> 사용자와 회사간의 소프트웨어 사용계약 종료시
              </li>
              <li><span>-</span> 고객의 데이터 삭제요청이 있을 시</li>
            </ul>
            <li>
              <span>3)</span>
              파기방법
            </li>
            <ul class="tab">
              <li>
                <span>-</span> 종이에 출력된 개인정보 : 분쇄기로 분쇄하거나 소각
              </li>
              <li>
                <span>-</span> 전자적 파일 형태로 저장된 개인정보 : 기록을
                재생할 수 없는 기술적 방법을 사용하여 삭제
              </li>
            </ul>
          </ul>
          <li>
            <span>②</span>
            고객으로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
            달성되었음에도 불구하고 다른 법령에 따라 제공받는 자 개인정보
            이용목적 제공하는 개인정보의 항목 보유 및 이용기간 농촌진흥청
            농약등의 구매자 기록 및 보존, 농약안전정보시스템 제공용 성명, 주소,
            연락처 사용자가 제3자 제공동의를 철회하는 때까지 개인정보를 계속
            보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로
            옮기거나 보관장소를 달리 하여 보존합니다.
          </li>
        </ul>
      </section>
      <section>
        <h2>제7조 (고객 및 법정대리인의 권리·의무 및 행사방법)</h2>
        <ul>
          <li>
            <span>①</span>
            회사는 고객의 권리를 보장하기 위해 개인정보의 열람·정정 또는 동의
            철회 방법을 개인정보의 수집방법보다 쉽게 할 수 있도록 절차를
            마련하고 있습니다. 고객(또는 법정대리인)은 언제든지 고객의
            개인정보를 열람하거나 수정 할 수 있으며, 고객 데이터 삭제요청을
            통하여 개인정보 이용에 대한 동의를 철회할 수 있습니다.
          </li>
          <li><span>1)</span> 개인정보의 열람 및 정정 범위</li>
          <ul class="tab">
            <li><span>-</span> 회사가 보유하고 있는 고객의 개인정보</li>
            <li><span>-</span> 회사가 이용하거나 제3자에 제공한 개인정보</li>
            <li><span>-</span> 개인정보 수집, 이용, 제공 등의 동의 현황</li>
          </ul>
          <li>
            <span>②</span> 고객이 개인정보의 오류 등에 대한 정정 또는 삭제를
            요구한 경우에는, 회사는 정정 또는 삭제를 완료할 때까지 당해
            개인정보를 이용하거나 제공하지 않습니다. 제5조 제1항 제2호, 동조
            동항 제3호, 동조 동항 제4호를 통해, 잘못된 개인정보를 제3자에게 이미
            제공한 경우에는, 정정 처리결과를 제3자에게 지체없이 통지하여
            정정하도록 조치하겠습니다.
          </li>
          <li>
            <span>③</span> 고객은 자신의 개인정보를 보호받을 권리와 함께
            스스로를 보호할 의무가 있습니다. 고객은 개인정보를 항상 최신의
            상태로 유지해야 하며, 고객이 사용자 및 회사에게 제공한 부정확한
            정보로 인하여 발생한 문제의 책임은 고객 자신에게 있습니다.
          </li>
        </ul>
      </section>
      <section>
        <h2>제8조 (의견수렴 및 불만처리)</h2>

        <ul>
          <li>
            <span>①</span> 회사는 고객의 의견을 소중하게 생각하며, 원활한 소통을
            위해 고객센터를 운영하고 있습니다. 고객센터를 통해 의견수렴 및
            불만처리에 도움을 드리고 있습니다. (1544-6301)
          </li>
          <li>
            <span>②</span> 개인정보에 관한 불만처리 및 피해구제 등 상담이 필요한
            경우에는 개인정보침해신고센터, 대검찰청 첨단범죄수사과, 경찰청
            사이버안전국 등으로 문의하실 수 있습니다.
          </li>
        </ul>
        <p>개인정보침해신고센터 (국번없이) 118 http://privacy.kisa.or.kr</p>
        <p>대검찰청 첨단범죄수사과 (국번없이) 1301 http://www.spo.go.kr</p>
        <p>
          경찰청 사이버안전국 (국번없이) 182 http://cyberbureau.police.go.kr
        </p>
      </section>
      <section>
        <h2>제9조 (개인정보의 기술적ㆍ관리적 보호조치)</h2>
        <p><span>①</span> 기술적 보호조치</p>
        <ul>
          <li>
            <span>1)</span> 회사는 고객의 개인정보를 보호함에 있어 개인정보가
            분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
            다음과 같은 기술적 보호조치를 강구하고 있습니다. - 고객의 개인정보는
            암호화하여 보호되며, 파일 및 전송 데이터를 암호화하거나 파일
            잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해
            보호되고 있습니다. - 백신프로그램을 이용하여 컴퓨터바이러스에 의한
            피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은
            주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이
            나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고
            있습니다.
          </li>
          <li>
            <span>2)</span> 회사는 해킹 등 외부 침입에 대비하여 각 서버마다
            침입차단시스템 및 취약점 분석 시스템을 등을 이용하여 보안에 만전을
            기하고 있습니다.
          </li>
        </ul>
        <p><span>②</span> 관리적 보호조치</p>
        <ul>
          <li>
            <span>1)</span> 회사는 고객의 개인정보에 대한 접근권한을 최소한의
            인원으로 제한하고 있습니다. 그 최소한의 인원에 해당하는자는 다음과
            같습니다.
          </li>
          <ul class="tab">
            <li>
              <span>-</span>개인정보보호 관리책임자 및 개인정보보호 실무담당자
              등 개인정보보호 업무를 수행하는 자
            </li>
            <li><span>-</span>기타 업무상 개인정보의 취급이 불가피한 자</li>
          </ul>
          <li>
            <span>2)</span> 개인정보를 취급하는 직원을 대상으로 새로운 보안 기술
            습득 및 개인정보보호의무 등에 관해 정기적인 사내교육을 실시하고
            있습니다.
          </li>
          <li>
            <span>3)</span> 회사는 내부직원들에 의한 정보유출을 사전에 방지하고
            개인정보보호 관련 규정에 대한 이행사항 및 직원의 준수여부를 감사하기
            위한 내부절차를 마련하고 있습니다.
          </li>
          <li>
            <span>4)</span> 개인정보보호 관련 취급자의 업무 인수인계는 보안이
            유지된 상태에서 철저하게 이뤄지고 있습니다.
          </li>
          <li>
            <span>5)</span> 회사는 사용자의 실수나 회사의 귀책사유 없이 발생하는
            개인정보관련 문제에 대해서는 책임을 지지 않습니다.
          </li>
        </ul>
      </section>
      <section>
        <h2>제10조 (개인정보보호 관리책임자)</h2>
        <p>
          회사는 고객의 정보를 안전하게 관리할 수 있도록 개인정보보호 관리책임자
          및 개인정보보호 실무담당자를 두고 있습니다. 기술적인 보완조치를
          했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해
          발생하는 예기치 못한 사고로 인한 정보의 훼손에 관해서는 책임이
          없습니다. 개인정보보호 관리책임자 및 개인정보보호 실무담당자는 다음과
          같으며, 개인정보보호 관련 문의사항에 신속하고 성실하게 답변해드리고
          있습니다.
        </p>
      </section>
      <section>
        <h3>개인정보보호 관리책임자</h3>
        <p>성명 : 이준혁</p>
        <p>이메일: junhyuck518@naver.com</p>
        <p>팩스: 02-6952-0457</p>
      </section>
      <section>
        <h3>개인정보보호 실무담당자</h3>
        <p>성명: 박보현</p>
        <p>이메일: woosungsoft@naver.com</p>
        <p>팩스: 02-6949-4379</p>
      </section>
      <section>
        <h2>제11조 (고지의 의무)</h2>
        <p>
          본 방침은 정부나 회사의 정책이 변경됨에 따라 내용의 추가 및
          삭제·수정이 있을 수 있으며, 이 경우 사용자 및 고객에게 지체없이
          안내공지하며, 이 정책은 공지한 날로부터 시행됩니다.
        </p>
        <ul>
          <li><span>-</span>개인정보처리방침 버전번호 : V2021-1.0</li>
          <li><span>-</span>개인정보취급방침 시행일자 : 2021. 08. 23</li>
        </ul>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "./terms.scss";
span {
  font-weight: bold;
}
p.flex {
  justify-content: flex-start;
  gap: 10px;
  margin: 10px 0;
}
.tab {
  padding-left: 20px;
}
table {
  width: 100%;
  thead {
    td {
      text-align: center;
    }
  }
  td {
    border: 1px solid #d0d0d0;
    vertical-align: middle;
    padding: 0 10px;
    font-size: 0.875rem;
  }
}
</style>
